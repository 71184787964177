import React, { useEffect } from 'react';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import {
  Banner,
  CardList,
  Header as SCHeader,
  Footer as SCFooter,
  Stepper,
  FaqList,
  ContactUs,
  AnchorLink as SCAnchorLink,
  TextWithIcon,
  useLivePersonChat,
} from 'sunshine-components';
import '@dls/web/assets/fonts.css';
import { withUrlStripping } from '../helpers/url';
import Helmet from 'react-helmet';
import { withPrefix, navigate } from 'gatsby';

const TestPageTemplate = props => {
  const { pageContext } = props;
  const seo = JSON.parse(pageContext.aemPageInfo);
  const sitemap = pageContext.sitemap ? JSON.parse(pageContext.sitemap) : '';
  const shortcode = shortcodes(sitemap);

  useLivePersonChat({
    lpSiteId: process.env.GATSBY_LIVEPERSON_SITE_ID,
    lpSection: process.env.GATSBY_LIVEPERSON_SECTION,
    lpEngagementId: process.env.GATSBY_LIVEPERSON_ENGAGEMENT_ID,
    lpScriptPath: withPrefix('liveperson/le-mtagconfig.js'),
  });

  useEffect(() => {
    if (seo.redirectTarget) {
      navigate(seo.redirectTarget);
    }
  }, [navigate, seo.redirectTarget]);

  if (seo.redirectTarget) {
    return null;
  }

  return (
    <>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={seo.title} />
        <meta property="og:description" content={seo.description} />
      </Helmet>
      <MDXProvider components={shortcode}>
        <MDXRenderer>{pageContext.jsx}</MDXRenderer>
      </MDXProvider>
    </>
  );
};

export default TestPageTemplate;

const shortcodes = siteInfo => {
  return {
    FaqList: withUrlStripping(FaqList, { key: 'viewAllUrl' }, siteInfo),
    ContactUs,
    Header: withUrlStripping(
      SCHeader,
      {
        key: ['logoUrl', 'menu.url'],
      },
      siteInfo
    ),
    Footer: SCFooter,
    CardList: withUrlStripping(
      CardList,
      { key: 'cards.cardbuttonLink' },
      siteInfo
    ),
    HeroProductBanner: withUrlStripping(Banner, { key: 'buttonUrl' }, siteInfo),
    Stepper: Stepper,
    AnchorLink: props => (
      <SCAnchorLink
        scrollMarginTop="72px"
        scrollMarginTopMobile="64px"
        {...props}
      />
    ),
    Padding: props => (
      <div style={{ padding: 20 }}>
        <h5>Padding</h5>
        {props.children}
      </div>
    ),
    HeadingText: props => (
      <div style={{ padding: 20 }}>
        <h5>HeadingText</h5>
        {props.children}
      </div>
    ),
    MarketingBanner: props => (
      <div style={{ padding: 20 }}>
        <h5>MarketingBanner</h5>
        {props.children}
      </div>
    ),
    Column: props => (
      <div style={{ padding: 20 }}>
        <h5>Column</h5>
        {props.children}
      </div>
    ),
    AnchorLocation: props => (
      <div style={{ padding: 20 }}>
        <h5>AnchorLocation</h5>
        {props.children}
      </div>
    ),
    SingtelProductCatalogue: props => (
      <div style={{ padding: 20 }}>
        <h5>SingtelProductCatalogue</h5>
        {props.children}
      </div>
    ),
    PageSection: props => (
      <div style={{ padding: 20 }}>
        <h5>PageSection</h5>
        {props.children}
      </div>
    ),
    FaqSupportArticleSingtel: props => (
      <div style={{ padding: 20 }}>
        <h5>FaqSupportArticleSingtel</h5>
        {props.children}
      </div>
    ),
    TextWithIcon: props => (
      <TextWithIcon
        lpDivId={process.env.GATSBY_LIVEPERSON_DIV_ID}
        lpEngagementId={process.env.GATSBY_LIVEPERSON_ENGAGEMENT_ID}
        {...props}
      />
    ),
  };
};
